import React from 'react'
import './LoginForm.css'
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
export const LoginForm = () => {
  return (
    <div className='wrapper'>
        <form acion="">
            <h1>Anmelden</h1>
            <div className='input-box'>
                <input type='text'placeholder='Username' required/>
                <FaUser className='icon'/>
            </div> 
            <div className='input-box'>
                <input type='text'placeholder='Password' required/>
                <FaLock className='icon'/>
            </div>
            <div className='remember-forgot'>
                <label><input type="checkbox" />Angemeldet bleiben</label>
                <a href="#">Passwort vergessen</a>
            </div>
            <button type='submit'>Anmelden</button>
            <div className='register-link'>
                <p>Du hast keinen Account?<a href="#">Registrieren</a></p>
            </div>
        </form>
    </div>
  )
}
